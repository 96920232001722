import React from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/styles';
import Box from '@material-ui/core/Box';
import axios from 'axios';
import swal from 'sweetalert';
import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import Autocomplete from '@material-ui/lab/Autocomplete';
import LinkMaterial from '@material-ui/core/Link';


let theme = createMuiTheme();
theme = responsiveFontSizes(theme);

const styles = ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    media: {
        margin: 10,
        width: 180,
        height: 120
    },
    media2: {
        margin: 10,
        width: 360,
        height: 120
    },
    bottom: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    palette: {
        primary: { main: "#e91e63", contrastText: "#fff" },
        secondary: { main: "#fff", contrastText: "#fff" }
    }
})

const camps = [
    { camp: 'Tokyo    / 01 April 2020 to 05 April 2020 / 13,000 Yen / Camp 1' },
    { camp: 'Sangli    / 15 April 2020 to 14 May 2020 / Rs.2,500 / Camp 1' },
    { camp: 'Singapore / 01 June 2020 to 06 June 2020 / 200 SGD / Camp 1' },
    { camp: 'Singapore / 15 June 2020 to 19 June 2020 / 200 SGD / Camp 2' },


]

class Feedback extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            acceptTerms: false,
            name: '',
            email: '',
            mobile: '',
            parentName: '',
            camp: '',
            date: new Date().toLocaleDateString()
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }



    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    }



    handleSubmit(event) {
        event.preventDefault();

        let camp = this.state.camp.camp;

        swal({
            title: camp,
            text: "You selected this camp, Do you want to proceed!",
            icon: "info",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    console.log(this.state);
                    const campReg = this.state;
                    campReg.camp = camp;
                    this.setState({
                        acceptTerms: false,
                        name: '',
                        mobile: '',
                        email: '',
                        camp: '',
                        parentName: ''
                    });
                    axios.post(`https://crickinngdom-com092018.firebaseio.com/summer-2020.json`, { campReg })
                        .then(res => {
                            swal("Thank You!", "Registration for CricKingdom Summer Camp Successful!", "success")
                        })
                } else {

                }
            });



    }




    render() {
        const classes = this.props.classes;

        return (
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <div className={classes.paper}>

                    <CardMedia
                        className={classes.media}
                        image={require("../../assets/logo.png")}
                        title="CricKingdom"
                    />
                    <Typography spacing={2} component="h1" variant="h5">
                        Summer Camp  2020
                    </Typography>
                    <Box fontStyle="italic">
                        <Typography spacing={2} variant="subtitle1">
                            High Performance Camp from CricKingdom ...
                    </Typography>
                    </Box>
                    <form onSubmit={this.handleSubmit} className={classes.form} noValidate>
                        <Grid container spacing={2}>
                            <Grid item xs={12} >
                                <Autocomplete
                                    required
                                    autoFocus
                                    autoHighlight={true}
                                    id="combo-box-demo"
                                    options={camps}
                                    getOptionLabel={option => option.camp}
                                    value={this.state.camp}
                                    onChange={(event, newValue) => {
                                        this.setState({
                                            'camp': newValue
                                        });
                                    }}
                                    renderInput={params => (
                                        <TextField {...params} label="Camp*" variant="outlined" fullWidth />
                                    )}
                                />
                                <Typography spacing={2} variant="caption" display="block" gutterBottom>
                                    Please Search with your City Name
                            </Typography>
                            </Grid>

                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={12} >
                                <TextField
                                    autoComplete="name"
                                    name="name"
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="name"
                                    label="Name"
                                    value={this.state.name}
                                    onChange={this.handleInputChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    variant="outlined"
                                    fullWidth
                                    id="mobile"
                                    label="Mobile"
                                    name="mobile"
                                    value={this.state.mobile}
                                    onChange={this.handleInputChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    id="email"
                                    label="Email"
                                    name="email"
                                    value={this.state.email}
                                    onChange={this.handleInputChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    id="parentName"
                                    label="ParentName"
                                    name="parentName"
                                    value={this.state.parentName}
                                    onChange={this.handleInputChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={<Checkbox name="acceptTerms" onChange={this.handleInputChange} checked={this.state.acceptTerms} color="primary" />}
                                    label={<React.Fragment>
                                        <Typography >By clicking, you agree to our
                                          <LinkMaterial href="https://crickingdom.com/terms" target="_blank" rel="noopener" > Terms </LinkMaterial> and
                                          <LinkMaterial href="https://crickingdom.com/privacy" target="_blank" rel="noopener"> Privacy Policy </LinkMaterial>
                                        </Typography>
                                    </React.Fragment>} />

                            </Grid>
                            {this.state.camp && this.state.mobile && this.state.acceptTerms && <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary">
                                Submit
                       </Button>}
                        </Grid>
                    </form>

                    <Grid item xs={12} className={classes.bottom} >
                        <Link href="https://crickingdom.com/registration" target="_blank" rel="noopener" variant="body2">
                            <Typography spacing={2} component="body1" variant="body1">
                                Click here for more Information on camps !!
                            </Typography>
                        </Link>
                    </Grid>
                </div>
            </Container>
        );
    }
}

export default withStyles(styles)(Feedback);